import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"
import Flecha from "../assets/flecha.svg"

const Galeria = ({ data }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const [flecha, setFlecha] = useState("opacity-0 invisible")

  const handleScroll = () => {
    const position = window.pageYOffset
    if (position < 600) {
      setFlecha("opacity-0 invisible")
    } else {
      setFlecha("visible opacity-100")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])
  const imagenes = data.galeria.nodes
  const schema = {
    "@context": t("SEO.galeria.schema.context"),
    "@type": t("SEO.galeria.schema.type"),
    name: t("SEO.galeria.schema.name"),
    description: t("SEO.galeria.schema.description"),
    url: t("SEO.galeria.schema.url"),
    image: t("SEO.galeria.schema.image"),
    sameAs: t("SEO.galeria.schema.sameAs"),
    jobTitle: t("SEO.galeria.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.galeria.datos.title"),
    description: t("SEO.galeria.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <AnchorLink
        to={t(`rutas.galeria`)}
        className={`${flecha} fixed bottom-14 right-8 md:bottom-28 md:right-10 w-12 h-12 rounded-full border-blanco border-2 bg-azul flex items-center justify-center transition-all duration-500 z-40 hover:-translate-y-2`}
      >
        <Flecha className="w-1/2" />
      </AnchorLink>
      <section className="container max-w-7xl mt-10 sm:mt-16 px-10">
        <h2 className="border-t border-b text-center py-4 text-3xl sm:text-4xl uppercase">
          {t("galeria")}
        </h2>
        <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-5">
          {imagenes.map((imagen, index) => (
            <div key={index} className="flex flex-col">
              <GatsbyImage
                image={getImage(imagen.childImageSharp)}
                className="w-full h-full object-contain"
                alt={imagen.name.substring(3)}
              />
              {imagen.name.substring(3) === "" ? null : (
                <p className="text-gris text-center w-full mt-2 font-sans text-xs">
                  {imagen.name.substring(3)}
                </p>
              )}
            </div>
          ))}
        </article>
      </section>
    </Layout>
  )
}

export default Galeria

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    galeria: allFile(
      sort: { order: DESC, fields: name }
      filter: { relativeDirectory: { eq: "galeria" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100)
        }
        name
      }
    }
  }
`
